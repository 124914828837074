import React, { useState, useEffect, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Layout from "layout"
import Message from "elements/Message"
import Container from "layout/Container"
import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"
import { isBrowser, isObjectEmpty } from "services/general"
import { useMentalHealthFormFields } from "./hooks/useAirtableMHFormFields"
import { generateFormField } from "elements/Form/services/form"
import { generateInitialValues } from "../../../services/context"

import { ConsultationContext } from "../ConsultationContext/ConsultationContext"

const EmployeeDetails = (props) => {
  let [loading, setLoading] = useState(false)
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )

  let exclude = ["-", "accessCode", "employeeId"]

  let { pageContext } = props
  const handleSubmit = async (values) => {
    setLoading(true)
    await consultationDispatch({
      type: "SAVE_MEDICAL_HISTORY",
      payload: {
        ...values,
      },
    })
    setLoading(false)
    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    if (isBrowser()) {
      consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [consultationDispatch])

  let formFields = pageContext.formFields.filter(
    (field) => !exclude.includes(field.name)
  )
  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields,
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...consultationState,
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {sectionFormFields
                .sort(
                  (firstFormField, secondFormField) =>
                    firstFormField.order - secondFormField.order
                )
                .map((section) => (
                  <Section
                    title={section?.section}
                    subtitle={section?.subtitle || ""}
                    id={section?.sectionId || ""}
                  >
                    {section?.message && (
                      <Message color="light">{section?.message}</Message>
                    )}
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: field,
                              values,
                              setFieldValue,
                              context: {
                                state: consultationState,
                                dispatch: consultationDispatch,
                              },
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </Section>
                ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{ label: "Continue", loading }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default EmployeeDetails
